const activity = "Activity"
const description = "Description"
const dataCollection = "Data collected to demonstrate effectiveness (leave blank if you’re not collecting data yet)"
const support = "Select a tier of support"
const timeSpan = "How long has your organization engaged in this activity?"
const promotion = "Complete the promt to describe how this activity promotes this"
const addTitle = "Please complete this activity"
const uploads = "Uploads"
const addNewUser = "Add New User"
const search = "Search"
const maximumLength = 5
const email = "Email"
const del = "Delete"
const edit = "Edit"
const firstName = "First Name"
const lastName = "Last Name"
const status = "Status"
const yes = "Yes"
const no = "No"
const programs = "Programs"
const schools = "Schools"
const role = "Role"
const ProgramAdminRoles = [
  { name: "school user", value: "school user" },
  { name: "program user", value: "program user" },
  { name: "school admin", value: "school admin" },
  { name: "program admin", value: "program admin" },
]
const SchoolAdminRoles = [
  { name: "school user", value: "school user" },
  { name: "school admin", value: "school admin" },
]
const statusTypes = [
  { name: "Yes", value: true },
  { name: "No", value: false },
]
const certified = "Certified"
const active = "Activate"
const inActive = "Deactivate"
const lastSignedIn = "Last Signed In"
const lastUpdated = "Last Updated"
const lastSubmitted = "Last Submitted"
const createdOn = "Created On"
const save = "Save"
const cancel = "Cancel"
const back = "Back"
const editDetails = "Edit Details"
const viewDetails = "User Details"
const sendEmail = "Send Email"
const subject = "Subject"
const to = "To"
const message = "Message"
const deactivateUsers = "Deactivate Users"
const activateUsers = "Activate Users"
const bulkEmail = "Bulk Email"
const batchAction = "Batch Action"
const programUser = "Program User"
const schoolUser = "School User"
const programAdmin = "Program Admin"
const schoolAdmin = "School Admin"
const matrixDoughnut = "Number Of Schools With Matrix Submissions"
const implementationDoughnut = "Number Of Schools With Implementation Rubric Submissions"
const sustainabilityDoughnut = "Number Of Schools With Sustainability Rubric Submissions"
const studentSEL = "Student SEL Quality"
const adultSEL = "Adult SEL Quality"
const studentSelContent = "Number of Schools with 5 Activities in Each Domain"
const school = "School"
const program = "Program"
const adultDomain = "Number of Schools with 5 Activities in Adult Domain"
const activitiesCount = "Activities Count By Matrix Competency"
const matrixBarChart1 = "Number of Schools with Universal Supports for Each Indicator in Each Competency"
const matrixBarChart2 = "Activities Count By Matrix Competency"
const countByDessa = "Activities Count By DESSA Competency"
const exportToCsv = "Export CSV"
const graphSustainability = "Number of Schools by Stage of Sustainability Rubric"
const graphImplementation = "Number of Schools with Scores of 75 or Higher in Each Stage"
const totalSchools = "Total Schools"
const schoolsWithSubmissions = "Schools With Submissions"
const schoolsWithoutSubmissions = "Schools Without Submissions"
const titleMatrix = "Matrix"
const titleImplementation = "Implementation"
const titleSustainability = "Sustainability"
const descriptionMatrix = "Organize activities contributing to SEL at least 2x every year"
const descriptionImplementation = "Plan and execute as you update your rubric throughout the year"
const descriptionSustainability = "Codify your solutions to strengthen your SEL program each year"
const addActivity = "Add Activity"
const finish = "Finish"
const next = "Next"
const programMatrix = "Program Matrix"
const completedActivities = "Completed Activities"
const viewReports = "View Reports"
const backToDashboard = "Back To Dashboard"
const adultActivity = "Adult activity"
const studentActivity = "Student Activity"
const universal = "Universal"
const selectTier = "Select a tier of support…"
const educatorsAccess = "The support is designed for all educators to have access"
const studentsAccess = "The support is designed for all students to have access"
const targeted = "Targeted"
const educatorsStrategicCohorts = "Educators in strategic cohorts gain access to the support"
const RiskFactorToGain = "For students who are selected or otherwise demonstrate a risk factor to gain access to the support"
const tertiary = "Tertiary"
const educatorsIndividualizedSupports = "Educators have access to individualized supports"
const highRiskStudentsIndividualizedSupports = "High risk students have access to individualized supports"
const howLong = "How long has your organization engaged in this activity?"
const dataCollected = "What data is collected to demonstrate effectiveness of the activity? (Leave blank if you're not collecting data yet)"
const selectUpto3 = " Select up to 3 SEL indicators…"
const ComprehensiveSel = "* For users implementing comprehensive SEL program, select all indicators that apply."
const ComprehensiveSels = "* For users implementing comprehensive SEL programs, select all indicators that apply."
const selectOne = "Select one…"
const promotes = "promotes"
const reinforces = "reinforces"
const promotesCap = "Promotes"
const reinforcesCap = "Reinforces"
const socialEmotional = "Social-emotional skills explicitly taught by the activity"
const socialEmotionalExperience = "Social-emotional skills activated through the experience"
const SelectSelIndicator = "Select the SEL indicator"
const completePrompt = "*Please complete the prompt"
const percentageUniversal = "Schools by Percentage of Universal Support Indicators"
const ratioIndicator = "Ratio between indicators that promote and reinforce SEL"
const noSpringScore = "* If the matrix is not updated between February and July, no spring score will display."
const schoolYearProgress = "School Year Progress"
const schoolWithMatrix = "Schools with Matrix Submissions"
const selIndicators = "Please select upto 3 SEL indicators"
const emailAllUsers = "Email All Users"
const sendEmailToAllUser = "Send Email To All Users"
const close = "Close"
const schoolsSubmissionsScores = "School Submission Scores"
const addSchoolUser = "Add To School"
const addProgramUser = "Add To Program"
const removeSchoolUser = "Remove From School"
const removeProgramUser = "Remove From Program"
const messageBatchActionRole = "Please update school users to program users before adding them to a program, and vice versa."
const adultSelMatrix = "adultSelMatrix"
const gaugeInside1 = "gaugeInside1"
const gaugeInside2 = "gaugeInside2"
const studentSelMatrix = "studentSelMatrix"
const studetMartixIndicator = "studetMartixIndicator"
const adultMatrixIndicator = "adultMatrixIndicator"
const dessaGraph = "dessaGraph"
const studentDessaGraph = "studentDessaGraph"
const sendButtonText = "Send"
const removeButtonText = "Remove"
const addButtonText = "Add"
const lastUpdatedWidget = "Last updated"
const lastSubmittedWidget = "Last submitted"
const universalActivities = "SEL Indicators with universal activities for students"
const organizeActivities = "Organize activities contributing to SEL at least 2x every year"
const percentageImplementation = "Percentage of Implementation Rubric submitted"
const executeUpdateRubric = "Plan and execute as you update your rubric throughout the year"
const sustainabilityRubricSubmitted = "Percentage of Sustainability Rubric submitted"
const codifySolutionsStrengthen = "Codify your solutions to strengthen your SEL program each year"
const brandonFrame = "Reach out to Brandon Frame at bframe@urbanassembly.org to get certified."
const qualityMeter = "SEL Quality Meter"
const reachBrandonFrameCertified = "Reach out to Brandon Frame at bframe@urbanassembly.org to get certified."
const attachemnts = "Attachments"
const addAttachment = "Add Attachment"
const view = "View"
const resendInvitation = "Resend Invitation"
const submitted = "Submitted"
const createNewRubric = "Create New Rubric"
const generateReport = "Generate Report"
const updateRubric = "Update Rubric"
const updateAndViewRubric = "View / Update rubric"
const currentSchoolPlan = "Current School Plan"
const commitmentStageI = "Commitment Stage I"
const commitmentStageII = "Commitment Stage II"
const programFocusAlignment = "Program Focus and Alignment"
const comStage = "District SEL supports demonstrate a strong understanding of social-emotional learning in alignment with district and school goals."
const implementationStageI = "Implementation Stage I"
const implementationStageII = "implementationStage II"
const execution = "Execution"
const instructionAssessment = "Instruction and Assessment"
const selIntegration = "SEL Integration"
const implStageI = "Allocations of resources are made for Social-Emotional Learning to be implemented in the district."
const implStageII = "Allocations of resources are made for integration of Social-Emotional Learning concepts."
const inCompleteText = "INCOMPLETE"
const implRubric = "Click on the Implementation Rubric stages below to expand them and then read each indicator and determine at what level your community has a plan for that indicator AND has executed that plan. Use your reflections from the Matrix to inform your answers. Update the rubric and click “Generate Report” at the beginning, middle, and end of the year as your plan is executed. Keep updating the same rubric until it is time to plan for the next year, then “Create New Implementation Rubric”."
const implPlan = "This report highlights in dark green indicators in which the execution of the plan is complete. Indicators that are nearing completion are highlighted in green, and indicators that are highlighted in light green represent indicators where there is currently no plan in place. Review these indicators and use them to plan your next steps. "
const implReport = "This report summarizes your progress towards implementing high quality SEL. Review the report and create action steps to increase your scores. The graph below compares your selected rubric to the most recent previous rubric."
const previousScore = "PREVIOUS SCORE"
const on = "ON"
const emerging = "EMERGING"
const initiating = "INITIATING"
const developing = "DEVELOPING"
const integrated = "INTEGRATED"
const indicatorType = "Indicator Type"
const indicatorInstructions = "*Please note that the selected program includes schools with different indicator types. To view data specific to a particular indicator type, please select the desired type from the dropdown menu."
const areasOfNeed = "Areas of need"
const areasOfStrength = "Areas of strength"
const indicatorTooltip0 = "No plan in place yet"
const indicatorTooltip1 = "Plan initiated, but not yet complete"
const indicatorTooltip2 = "Plan complete and execution begun"
const indicatorTooltip3 = "Execution of plan nearing completion"
const indicatorTooltip4 = "Execution of plan complete"
const scrollerText = "Back to Top"
const noReportGenerated = "No Report Generated"
const noVideoUploaded = "No video available"
const sustainabilityRubric = "Complete the Sustainability Rubric to reflect on your SEL implementation and to house artifacts that reflect your school’s solutions around indicators of sustainability. Score each component of a category and then upload an artifact to justify your score."
const ungraded = "Ungraded"
const evidenceCheckbox = " I would like this response to be shared as an exemplar with the RSP online community."
const newSustainabilityReportContent = "Complete the Sustainability Rubric to reflect on your SEL implementation and to house artifacts that reflect your school’s solutions around indicators of sustainability. Score each component of a category and then upload an artifact to justify your score."
const descriptionCurrentSchoolReport = "This report highlights in dark green indicators in which the execution of the plan is complete. Indicators that are nearing completion are highlighted in green, and indicators that are highlighted in light green represent indicators where there is currently no plan in place. Review these indicators and use them to plan your next steps."
const artifacts = "Artifacts"
const evidence = "Evidence"
const notSubmitted = "Not Submitted"
const viewRubrics = "View Rubrics"
const RatioOfUniversalTargetedTertiary = " Ratio of universal, targeted, and tertiary activities"
const noSchoolString = "No school has been associated. Please contact"
const TierType = "Tier Type"
const Domain = "Domain"
const LengthofTime = "Length of Time"
const DataCollected = "Data Collected"
const noProgramString = "No program has been associated. Please contact"
const NoActivityExists = "No activity exists"
const actual = "ACTUAL"
const vs = "vs."
const goal = "GOAL"
const studentSELQuality = "Student SEL"
const adultSELQuality = "Adult SEL"
const universalSupportIndicators = " Universal support indicators"
const activitiesData = "Activities with data"
const forgotPassword = "Forgot your password?"
const sendReset = "Send me reset password instructions"
const selActivities = "Activities"
const nodataAvailable = "No data available"
const categoryChangeMessage = "Changing the activity will delete Indicators. Are you sure?"
const indicatorChangemessage = "The activity indicator has been successfully deleted."

const improvisedTextforopportunity = "Improvised Text for Opportunities for growth across"
const improvisedTextforNeed = "Improvised Text for Area of Strength across"
const createActivity = "Create this Activity"
const studentsCanBeSupported = "Students can be supported"
const studentsAreBeingSupported = "Students are being supported"
const programMatrixDescription = "Write description for"
const selSchoolCapacity = "SEL School Capacity"
const numberStudentSelIndicator = "Number Of Students Supported By SEL Indicator"
const numberStudentMatrixCompetency = "Number Of Students Supported By Matrix Competency"
const numberStudentDessaCompetency = "Number Of Students Supported By DESSA Competency"
const comparisonTargetTertiary = "for Targeted and Tertiary Activities"
const editStudentBeingSupported = "To edit, kindly remove the value for Student Being Supported"
const noUpdation = "Please look for the latest scores in "
const practiceNotFound = "Practice not found"
const couldNotFound = "Could not save changes"
const couldNotFoundMessage = "please enter value in students can be supported first"
const seeMore = "See More"
const definition = "Definition"
const instrauctionalPractices = "Instructional Practices Activities"
const socialEmotionaSupport = "Social Emotional Support Activities"
const behaviourSupport = "Behavior Support Activities"
const extracurricularActivities = "Extracurricular Activities"
const adultSel = "Adult SEL"
const noDateSelected = "No date selected"
const completedTenure = "The tenure of this rubric has ended. Please generate a report to create a new rubric."
const notification = "Notification"
const backToActivityDashboard = "Back to activity dashboard"
const endPointUpdateStudentsCanBeSupported = "update_students_can_supported"
const noRating = "No indicator rating has been selected."
const indicator = "Indicator"
const rating = "Rating"
const tenureCompletionAlert = "Please press the button below to generate a report as the tenure of this rubric has come to an end."
const left = "left"
const implementationDetails = "Implementation Details"
const pdfContent = "pdfContent"
const unSubmitting = "Unsubmitting…"
const noSchoolAssociationText = "No school has been associated with"
const changeFilterType = "Change Filter Type"
const noData = "No data available"
const noVideo = "No videos found for"
const browserSupport = " Your browser does not support the video tag."
const lastUpdatedDate = "Date at which the indicator was updated"
const lastSubmittedDate = "Date at which the report was generated"
const noVideosAvailable = "No videos Available"
const noDataAdded = "Data has not yet been collected."
const noCategory = "No categories available"
const tierType = "Tier Type"
const domain = "Domain"
const timeLength = "Length of Time"
const noScoreYet = "No Score yet"
const noEvidenceSubmitted = "No evidence submitted"
const gradeLevelTitle = "School Grade Levels"
const activityToGradeLevel = "Activity to grade level"
const gradeLevel = "Grade Level Filter for Graphs"
const gradePopulation = "Please enter student population for each grade"
const noOfactivities = "Number of Activities"
const selectedGrades = "Selected grade Level"
const gradeDeselect = "Grade Removal Confirmation"
const grade = "Grade"
const enterGradeError = "ⓘ Data Required: Please enter grade levels and total student population in order to enter new activities or update the matrix."
const reportsDataError = "ⓘ Data needed to view reports: Please enter the number of students who can and are being supported for targeted and tertiary activities.  Reports will not display accurately until this information is entered"
const followingGraphs = "ⓘ Following graphs will be affected"

const pdfPreparing = " Preparing your PDF"
const download = "Download"
const pdfReady = "PDF is ready to download for selected filters"
const pdfReadyselQuality = "PDF is ready to download for selected filter"
const waiting = "Please wait..."
const NoDataExists = "No Data exists"
const pdfReadywithoutFilters = "PDF is ready to download"
const gradeLevelTitleFilter = "Grade Level:"
const pdfFiltertitle = "Following filters have been applied to download the PDF"
const pdfFiltertitleselQuality = "Following filter has been applied to download the PDF"
const studentsBeingerror = "Students being supported field cannot be greater than students who can be supported."
const releaseNotification = "Release Note Notification"
const releaseReview = " Please review our updates since you last logged in."
const newFeatures = "New Features"
const newFeature = "New Feature"
const noVideoavailable = "No video available for this feature."
const greeting = "Thank you for your continued support!"
const watchVideo = "Watch Video"
const releaseNews = "We are excited to announce the release of Version"
const releaseAlert = "of our application! Here are the key updates and improvements:"
const viewReleaseNotesMessage = "Release notes updated successfully."
const noActivity = "No Activity"
const skip = "Skip"
const defaultDescription = "Default description for this step."
const welcomeTour = "Welcome to the Resilient Scholars Portal Tutorial:"
const howtoAddmatrix = "How to Add Matrix?"
const howtoAddImplementation = "How to Add Implementation?"
const howtoAddSustainability = "How to Add Sustainability?"
const aboutTodownload = "You're about to download the presentation file in"
const file = "format. Click the button below to start the download."
const promotionText = "Promotion"
const reinforcementText = "Reinforcement"
const activityBreakdown = "  Activity Breakdown by Tier of Support"
const activitiesTitle = "Activities"
const tierTitle = "Tier"
const noActivityavailabe = "No activities available."
const dataOverview = "Data Overview Using MTSS Triangle"
const couching = "activities support student development better?"
const replayTutorial = "Replay Tutorial"
const promotedReinforcedIndicators = "SEL Indicators being Promoted and Reinforced"
const clearAll = "  Clear All"
const couchingTitle = "Coaching/Support"
const noIndicator = "No indicators available"
const schoolVision = "School Vision Statement"
const schoolGoal = "School Goals for the Year"
const noGoals = "No Goals Defined"
const visionStatement = "No Vision Statement Defined"
const activityDetails = "Activity Details"
const definitions = "Definitions"
const capacityvsActual = "Capacity vs. Actual for Targeted and Tertiary Activities"
export default {
  capacityvsActual,
  definitions,
  activityDetails,
  visionStatement,
  noGoals,
  schoolGoal,
  schoolVision,
  noIndicator,
  couchingTitle,
  promotedReinforcedIndicators,
  clearAll,
  couching,
  replayTutorial,
  dataOverview,
  noActivityavailabe,
  tierTitle,
  activitiesTitle,
  activityBreakdown,
  promotionText,
  reinforcementText,
  file,
  aboutTodownload,
  howtoAddmatrix,
  howtoAddImplementation,
  howtoAddSustainability,
  welcomeTour,
  defaultDescription,
  skip,
  newFeature,
  noActivity,
  viewReleaseNotesMessage,
  releaseAlert,
  releaseNews,
  watchVideo,
  greeting,
  noVideoavailable,
  newFeatures,
  releaseReview,
  releaseNotification,
  pdfFiltertitleselQuality,
  pdfFiltertitle,
  studentsBeingerror,
  gradeLevelTitleFilter,
  NoDataExists,
  pdfReadywithoutFilters,
  followingGraphs,
  waiting,
  pdfReady,
  pdfReadyselQuality,
  download,
  pdfPreparing,
  reportsDataError,
  enterGradeError,
  grade,
  gradeDeselect,
  selectedGrades,
  gradePopulation,
  noOfactivities,
  gradeLevel,
  activityToGradeLevel,
  gradeLevelTitle,
  noEvidenceSubmitted,
  noScoreYet,
  tierType,
  timeLength,
  domain,
  noCategory,
  noDataAdded,
  noData,
  noVideosAvailable,
  lastUpdatedDate,
  lastSubmittedDate,
  noVideo,
  browserSupport,
  changeFilterType,
  unSubmitting,
  updateAndViewRubric,

  noSchoolAssociationText,
  pdfContent,
  implementationDetails,
  left,
  tenureCompletionAlert,
  noRating,
  indicator,
  rating,
  notification,
  completedTenure,
  endPointUpdateStudentsCanBeSupported,
  backToActivityDashboard,
  adultSel,
  noDateSelected,
  socialEmotionaSupport,
  extracurricularActivities,
  behaviourSupport,
  instrauctionalPractices,
  definition,
  seeMore,
  noUpdation,
  couldNotFoundMessage,
  couldNotFound,
  practiceNotFound,
  editStudentBeingSupported,
  comparisonTargetTertiary,
  numberStudentDessaCompetency,
  numberStudentMatrixCompetency,
  numberStudentSelIndicator,
  selSchoolCapacity,
  studentsAreBeingSupported,
  studentsCanBeSupported,
  programMatrixDescription,
  createActivity,
  indicatorChangemessage,
  categoryChangeMessage,
  improvisedTextforopportunity,
  improvisedTextforNeed,
  initiating,
  selActivities,
  nodataAvailable,
  sendReset,
  forgotPassword,
  activitiesData,
  universalSupportIndicators,
  studentSELQuality,
  adultSELQuality,
  vs,
  actual,
  goal,
  NoActivityExists,
  DataCollected,
  LengthofTime,
  Domain,
  TierType,
  RatioOfUniversalTargetedTertiary,
  noProgramString,
  noSchoolString,
  viewRubrics,
  notSubmitted,
  descriptionCurrentSchoolReport,
  evidenceCheckbox,
  ungraded,
  noReportGenerated,
  noVideoUploaded,
  sustainabilityRubric,
  implReport,
  areasOfStrength,
  areasOfNeed,
  integrated,
  developing,
  emerging,
  on,
  previousScore,
  implRubric,
  implPlan,
  inCompleteText,
  implStageII,
  implStageI,
  selIntegration,
  instructionAssessment,
  execution,
  implementationStageII,
  implementationStageI,
  comStage,
  programFocusAlignment,
  commitmentStageII,
  commitmentStageI,
  currentSchoolPlan,
  updateRubric,
  generateReport,
  createNewRubric,
  submitted,

  indicatorInstructions,
  indicatorType,
  resendInvitation,
  reachBrandonFrameCertified,
  qualityMeter,
  brandonFrame,
  codifySolutionsStrengthen,
  sustainabilityRubricSubmitted,
  executeUpdateRubric,
  percentageImplementation,
  lastSubmittedWidget,
  organizeActivities,
  universalActivities,
  lastUpdatedWidget,
  view,
  addAttachment,
  attachemnts,
  gaugeInside2,
  studentDessaGraph,
  dessaGraph,
  adultMatrixIndicator,
  studetMartixIndicator,
  studentSelMatrix,
  gaugeInside1,
  adultSelMatrix,
  addButtonText,
  removeButtonText,
  sendButtonText,
  messageBatchActionRole,
  removeProgramUser,
  removeSchoolUser,
  addProgramUser,
  addSchoolUser,
  schoolsSubmissionsScores,
  close,
  sendEmailToAllUser,
  emailAllUsers,
  selIndicators,
  schoolWithMatrix,
  schoolYearProgress,
  noSpringScore,
  ratioIndicator,
  percentageUniversal,
  completePrompt,
  ComprehensiveSels,
  SelectSelIndicator,
  socialEmotionalExperience,
  socialEmotional,
  reinforces,
  promotes,
  promotesCap,
  reinforcesCap,
  selectOne,
  ComprehensiveSel,
  selectUpto3,
  dataCollected,
  howLong,
  highRiskStudentsIndividualizedSupports,
  educatorsIndividualizedSupports,
  tertiary,
  RiskFactorToGain,
  educatorsStrategicCohorts,
  targeted,
  studentsAccess,
  educatorsAccess,
  selectTier,
  universal,
  studentActivity,
  adultActivity,
  viewReports,
  backToDashboard,
  completedActivities,
  programMatrix,
  next,
  finish,
  addActivity,
  descriptionSustainability,
  descriptionImplementation,
  descriptionMatrix,
  titleSustainability,
  titleImplementation,
  titleMatrix,
  schoolsWithoutSubmissions,
  schoolsWithSubmissions,
  totalSchools,
  graphImplementation,
  graphSustainability,
  exportToCsv,
  matrixBarChart2,
  countByDessa,
  activitiesCount,
  adultDomain,
  adultSEL,
  program,
  school,
  matrixBarChart1,
  studentSelContent,
  studentSEL,
  sustainabilityDoughnut,
  implementationDoughnut,
  matrixDoughnut,
  programUser,
  schoolUser,
  batchAction,
  deactivateUsers,
  activateUsers,
  bulkEmail,
  message,
  to,
  subject,
  sendEmail,
  editDetails,
  viewDetails,
  save,
  cancel,
  back,
  createdOn,
  lastUpdated,
  lastSubmitted,
  lastSignedIn,
  active,
  inActive,
  certified,
  schools,
  programs,
  yes,
  no,
  status,
  lastName,
  firstName,
  email,
  edit,
  del,
  search,
  addNewUser,
  uploads,
  activity,
  description,
  dataCollection,
  support,
  timeSpan,
  promotion,
  addTitle,
  maximumLength,
  role,
  ProgramAdminRoles,
  SchoolAdminRoles,
  statusTypes,
  programAdmin,
  schoolAdmin,

  indicatorTooltip0,
  indicatorTooltip1,
  indicatorTooltip2,
  indicatorTooltip3,
  indicatorTooltip4,
  scrollerText,
  newSustainabilityReportContent,
  artifacts,
  evidence,
}
