import React, { useState } from "react"
import { Input, Button } from "antd"
import { EditOutlined } from "@ant-design/icons"
import Vision from "../../assets/images/Vision.png"
import group from "../../assets/images/group.png"
import commonStrings from "../commonStrings"
import { isUserAdmin, stripHtmlTags } from "../utilities"

const { TextArea } = Input

const SchoolBranding = ({
  currentSchool,
  currentUser,
  updateSchool,
  implementationRubricIndicators,
}) => {
  const visionIndicator = implementationRubricIndicators.find(
    (indicator) => indicator.index === 4,
  )

  const indicatorVisionStatement = visionIndicator && visionIndicator.explanation
    ? visionIndicator.explanation.trim()
    : ""

  const initialVisionStatement = currentSchool
    && currentSchool.vision_statement
    && currentSchool.vision_statement.trim()
    ? currentSchool.vision_statement.trim()
    : indicatorVisionStatement

  const [isEditingVision, setIsEditingVision] = useState(false)
  const [isEditingGoals, setIsEditingGoals] = useState(false)
  const [goals, setGoals] = useState(currentSchool.goals || "")
  const [visionStatement, setVisionStatement] = useState(
    initialVisionStatement,
  )

  const handleSaveVision = () => {
    const trimmedVision = visionStatement.trim()

    updateSchool(
      currentSchool,
      { vision_statement: trimmedVision || "" },
      (response) => {
        if (response.success) {
          setVisionStatement(response.school.vision_statement)
        }
        setIsEditingVision(false)
      },
    )
    setIsEditingVision(false)
  }

  const handleCancelVision = () => {
    setIsEditingVision(false)
    setVisionStatement(currentSchool.vision_statement || "")
  }

  const handleSaveGoals = () => {
    const trimmedGoals = goals.trim()

    updateSchool(currentSchool, { goals: trimmedGoals || "" }, (response) => {
      if (response.success) {
        setGoals(response.school.goals)
      }
      setIsEditingGoals(false)
    })
    setIsEditingGoals(false)
  }

  const handleCancelGoals = () => {
    setIsEditingGoals(false)
    setGoals(currentSchool.goals || "")
  }

  return (
    <div className="school-dashboard">
      <div className="vision-statement">
        <div className="header-branding red">
          <span>
            <img src={Vision} alt="vision" />
          </span>
          <span className="title-branding">{commonStrings.schoolVision}</span>

          {isUserAdmin(currentUser) && !isEditingVision && (
            <div className="main-edit">
              <div
                className="edit-borders"
                onClick={() => setIsEditingVision(true)}
              >
                <span className="edit-icon-img">
                  <EditOutlined
                    className="edit-icon"
                    style={{ color: "white" }}
                  />
                </span>
                <span style={{ marginRight: "8px", color: "white" }}>Edit</span>
              </div>
            </div>
          )}
        </div>
        <div className="content-branding custom-scrollbar-branding">
          {isEditingVision ? (
            <>
              <TextArea
                value={stripHtmlTags(visionStatement)}
                onChange={(e) => setVisionStatement(e.target.value)}
                autoSize={{ minRows: 3, maxRows: 6 }}
                placeholder={commonStrings.visionStatement}
              />
              <div className="saveEdit">
                <Button
                  className="save-button"
                  type="primary"
                  onClick={handleSaveVision}
                >
                  Save
                </Button>
                <Button
                  className="edit-button"
                  style={{ marginLeft: "10px" }}
                  onClick={handleCancelVision}
                >
                  Cancel
                </Button>
              </div>
            </>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: visionStatement || commonStrings.visionStatement,
              }}
            />
          )}
        </div>
      </div>

      <div className="goal-for-year">
        <div className="header-branding teal">
          <img src={group} alt="group" />
          <span className="title-branding">{commonStrings.schoolGoal}</span>

          {isUserAdmin(currentUser) && !isEditingGoals && (
            <div className="main-edit">
              <div
                className="edit-borders"
                onClick={() => setIsEditingGoals(true)}
              >
                <span className="edit-icon-img">
                  <EditOutlined
                    className="edit-icon"
                    style={{ color: "white" }}
                  />
                </span>
                <span style={{ marginRight: "8px", color: "white" }}>Edit</span>
              </div>
            </div>
          )}
        </div>
        <div className="content-branding custom-scrollbar-branding">
          {isEditingGoals ? (
            <>
              <TextArea
                value={stripHtmlTags(goals)}
                onChange={(e) => setGoals(e.target.value)}
                autoSize={{ minRows: 3, maxRows: 6 }}
                placeholder={commonStrings.noGoals}
              />
              <div className="saveEdit">
                <Button
                  className="save-button-goals"
                  type="primary"
                  onClick={handleSaveGoals}
                >
                  Save
                </Button>
                <Button
                  className="edit-button"
                  style={{ marginLeft: "10px" }}
                  onClick={handleCancelGoals}
                >
                  Cancel
                </Button>
              </div>
            </>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: goals || commonStrings.noGoals,
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SchoolBranding
