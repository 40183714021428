import React from "react"
import { Bar } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import Chart from "chart.js/auto"
import {
  uaDarkBlue, uaGold, uaTeal, uaRed,
} from "../../enums"

Chart.register(ChartDataLabels)
function HorizontalBarChart({
  labels,
  values,
  goals,
  onClick,
  colors = [uaTeal, uaGold, uaDarkBlue, uaRed],
  type,
}) {
  const BackgroundColor = type === "adult" ? "#33485D" : `rgba(${colors[0]}, 0.75)`
  const actualData = {
    label: "Actual",
    display: false,
    data: values.map((d) => parseInt(d, 10)),
    backgroundColor: BackgroundColor,
    barPercentage: 0.6,
    datalabels: {
      align: "end",
      anchor: "end",
      formatter: (value) => `${value}%`,

      color: () => {
        if (type === "adult" && !values.some((val) => val > 0)) {
          return "#33485D"
        }
        return "#0CA4A5"
      },
      font: {
        size: 16,
        weight: "700",
      },
    },
  }

  const goalData = {
    label: "Goals",
    data: goals.map((d) => parseInt(d, 10)),
    backgroundColor: "rgba(231, 169, 34, 0.4)",
    barPercentage: 0.6,
    datalabels: {
      align: "end",
      anchor: "end",
      formatter: (value) => `${value}%`,
      color: "#E7A922",
      font: {
        size: 16,
        weight: "700",
      },
    },
  }
  const data = {
    labels: labels || [],
    datasets: [actualData, goalData],
  }

  const options = {
    indexAxis: "y",
    onClick: (event, element) => {
      if (element.length) {
        const clickedDataset = data.datasets[element[0].datasetIndex]
        if (clickedDataset.label === "Actual" && onClick) {
          onClick(element[0].index)
        }
      }
    },
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement.length
        && data.datasets[chartElement[0].datasetIndex] === actualData
        ? "pointer"
        : "default"
    },
    plugins: {
      datalabels: {
        align: "end",
        anchor: "end",
        color: "#0CA4A5",
        font: {
          size: 16,
          weight: "700",
        },
        formatter: (value, context) => {
          if (context.dataset.label === "Actual") {
            return `${value}%`
          }
          return value
        },
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          color: "#000000",
          font: {
            size: 16,
            weight: "700",
          },
        },
      },
      x: {
        beginAtZero: true,
        max: 120,
        ticks: {
          callback(value) {
            if (value === 120) {
              return ""
            }
            if (value === 0) {
              return "0"
            }
            return `${value}%`
          },
        },
      },
    },
  }

  return (
    <div className="mb-3 chart-container">
      <Bar data={data} options={options} />
    </div>
  )
}

export default HorizontalBarChart
