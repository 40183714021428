import React, { useState } from "react"
import {
  Row, Col, Modal, Card,
} from "antd"
import { DatabaseFilled, CloseCircleOutlined } from "@ant-design/icons"
import ReactPlayer from "react-player"

import { formatDateforVideo } from "../utilities"
import commonStrings from "../commonStrings"
import Playicon from "../../assets/images/Playicon.png"
import IframePlayer from "./Iframe"

const ReleaseVideoCard = ({ latestReleaseNotes }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState(null)

  const handleCardClick = (video) => {
    setSelectedVideo(video)
    setIsModalVisible(true)
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
    setSelectedVideo(null)
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        {latestReleaseNotes.length > 0 ? (
          latestReleaseNotes.map((video, index) => {
            const dateToFormat = video.updated_at || video.created_at
            const formattedDate = formatDateforVideo(dateToFormat)
            return (
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={8}
                key={index}
                className="video-col-style"
              >
                <Card
                  hoverable
                  className="video-card"
                  onClick={() => handleCardClick(video)}
                  cover={(
                    <div className="ant-card-cover">
                      {video && video.video && video.video.url ? (
                        <>
                          <video preload="metadata">
                            <source src={video.video.url} type="video/mp4" />
                            <track
                              kind="captions"
                              srcLang="en"
                              label="English captions"
                              default
                            />
                            {commonStrings.browserSupport}
                          </video>

                          <img
                            src={Playicon}
                            alt="play"
                            className="play-icon"
                          />
                        </>
                      ) : video && video.attachment_link ? (
                        <IframePlayer
                          src={video.attachment_link.replace(
                            "watch?v=",
                            "embed/",
                          )}
                          width="100%"
                          height={500}
                          title="YouTube video player"
                          controls={false}
                          disableInteraction
                        />
                      ) : (
                        <div className="no-video">
                          {commonStrings.noVideoUploaded}
                        </div>
                      )}
                    </div>
                  )}
                  bodyStyle={{ textAlign: "left" }}
                >
                  <div className="release-version">
                    Version {video.release_version || "N/A"}
                  </div>
                  <div className="description-modals-container custom-scrollbar">
                    <div className="description-modals">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: video.description,
                        }}
                      />
                    </div>
                  </div>

                  <div className="updated-at">
                    <span>{formattedDate}</span>
                  </div>
                </Card>
              </Col>
            )
          })
        ) : (
          <Col xs={24} sm={24} md={24} lg={24}>
            <div>
              <div className="no-data">
                <DatabaseFilled />
              </div>
              <div className="no-activity mt-2">
                <span>{commonStrings.noVideoUploaded}</span>
              </div>
            </div>
          </Col>
        )}
      </Row>

      {selectedVideo && (
        <Modal
          visible={isModalVisible}
          className="video-modal"
          closable={false}
          footer={null}
          title={(
            <div>
              <span>Version {selectedVideo.release_version}</span>

              <span className="close-button" onClick={handleModalClose}>
                <CloseCircleOutlined />
              </span>
            </div>
          )}
        >
          <div className="custom-player">
            {selectedVideo && selectedVideo.video && selectedVideo.video.url ? (
              <ReactPlayer
                url={selectedVideo.video.url}
                controls
                width="689px"
                height="390px"
                className="react-player"
              />
            ) : selectedVideo && selectedVideo.attachment_link ? (
              <IframePlayer
                src={selectedVideo.attachment_link.replace(
                  "watch?v=",
                  "embed/",
                )}
                width="689px"
                height="390px"
                title="YouTube video player"
              />
            ) : null}
          </div>

          <div className="modal-description-container ">
            <div className="modal-description custom-scrollbar">
              <div className="release-message">
                {commonStrings.releaseNews} {selectedVideo.release_version}{" "}
                {commonStrings.releaseAlert}
              </div>

              <div className="new-feature">
                <span className="new-feature-text">
                  {commonStrings.description}
                </span>
              </div>
              <div
                className="new-features-description"
                dangerouslySetInnerHTML={{
                  __html: selectedVideo.description,
                }}
              />
              <div className="new-feature">
                <span className="new-feature-text">
                  {commonStrings.newFeatures}
                </span>
              </div>
              <div
                className="new-features-description"
                dangerouslySetInnerHTML={{
                  __html: selectedVideo.new_features,
                }}
              />
              <div className="main-new-features-description">
                <span className="thankyou-text-modal">
                  {commonStrings.greeting}
                </span>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ReleaseVideoCard
