import React from "react"
import PropTypes from "prop-types"

const IframePlayer = ({
  src,
  width,
  height,
  title,
  allowFullScreen,
  allow,
  className,
  frameBorder,
  style,
  disableInteraction, // New prop to disable interaction
}) => (
  <div style={{ position: "relative", width, height }}>
    <iframe
      src={src}
      width="100%"
      height="100%"
      title={title}
      frameBorder={frameBorder}
      allow={allow}
      allowFullScreen={allowFullScreen}
      className={className}
      style={{
        ...style,
        ...(disableInteraction ? { pointerEvents: "none" } : {}), // Apply non-interactive behavior
      }}
    />
    {disableInteraction && (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "transparent",
        }}
      />
    )}
  </div>
)

IframePlayer.defaultProps = {
  width: "100%",
  height: "500",
  title: "Iframe player",
  allowFullScreen: true,
  allow:
    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
  className: "",
  frameBorder: "0",
  style: {},
  disableInteraction: false, // Default allows interaction
}

IframePlayer.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  allowFullScreen: PropTypes.bool,
  allow: PropTypes.string,
  className: PropTypes.string,
  frameBorder: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  disableInteraction: PropTypes.bool, // Prop to disable interaction
}

export default IframePlayer
