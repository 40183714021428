const CommonConstants = {
  tierPriority: ["Tertiary", "Targeted", "Universal"],

  descriptionsResources: {
    Data: "Valid data is collected periodically and used to review and improve program implementation.",
    "Program Review":
      "The school has taken steps to incorporate program delivery into relevant ongoing and future activities.",
    Coordination:
      "SEL Leadership Team and implementing staff can communicate how the program fits with past, ongoing and/or future activities in the school.",
    Communication:
      "There is ongoing communication about the program within the school.",
    "Physical Visibility":
      "There is evidence that program use is visible within the school and to the general public.",
    Leadership:
      "SEL Leadership Team have a clear vision for the program and are active in program implementation.",
    "Program Fit/Reinvention":
      "There is evidence that the program has been adapted to meet specific needs of the school.",
    Range: "Evidence of program concepts embedded in a variety of contexts.",
    "School Norms":
      "Program concepts have been routinized with fidelity in the school.",
  },

  fileExtensions: [".pdf", ".png", ".jpeg", ".gif", "jpg"],

  matrixDescriptions: [
    {
      title: "Why should I use the RSP Matrix?",
      content: `<div>
         <h3 class="title-tour">Why should I use the RSP Matrix?</h3>
         <span>
           <strong>Purpose: </strong>The first step in your SEL journey is to understand what SEL work 
           is already happening at your school. The Matrix allows you to organize activities 
           that are happening at your school that contribute to SEL. It ensures that all 
           activities are well-documented, making it easier to track progress, identify gaps, 
           and allocate resources efficiently.
         </span>
       </div>`,
    },
    {
      title: "Accessing the Matrix",
      content: `<div>
         <h3 class="title-tour">Accessing the Matrix</h3>
         <p>
           From your Resilient Scholars Portal main dashboard navigate to the “Program Matrix” section from the dashboard or the menu at the top.
           </p>
       </div>`,
    },
    {
      title: "Matrix Dashboard and Domains",
      content: `<div>
         <h3 class="title-tour">Matrix Dashboard and Domains</h3>
          <p>In the Matrix Dashboard, start by selecting grades from the dropdown on the left and entering the student population on the right.</p>
          <p> Next, choose a domain to add an activity to:</p>
             <ul class="bold-list">
              <li>Instructional Practices</li>
              <li>Social Emotional Supports</li>
              <li>Behavior Supports</li>
              <li>Extracurricular Activities</li>
              <li>Adult SEL</li>
            </ul>
           </li>
           <li class="tour-sub-description">You can add an activity in two ways:
             <ul>
              <li>Create your own.</li>
              <li>Select from system-provided activities listed under each domain.</li>
            </ul>
           </li>
       </div>`,
    },
    {
      title: "Adding Activities - Step 1",
      content: `<div>
       <h3 class="title-tour">Adding an Activity (Creating Activities)</h3>
    <p>Click <strong>'Add Activity'</strong> in the relevant domain</p>
    <p>Manually enter the following details (* are required fields):</p>
   <ol>
  <li><strong>Activity Name – </strong> Be clear and concise.</li>
  <li><strong>Tier of Support – </strong> Select <strong>Universal, Targeted,</strong> or <strong>Tertiary</strong>.
    <ul>
      <li><strong>Universal:</strong> Automatically populates the total number of students.</li>
      <li><strong>Targeted/Tertiary:</strong> Specify grade levels, the number of students the activity can support, and the number it currently supports.</li>
    </ul>
  </li>
</ol>
  </div>`,
    },
    {
      title: "Adding Activities - Step 1",
      content: `<div>
       <h3 class="title-tour">Adding an Activity (Creating Activities Continued)</h3>
    <span><strong>Creating an Activity:</strong></span>
   <ol type="1" start="3">
   <li><strong>Activity Length – </strong> Select how long your school has engaged in this activity.</li>
      <li><strong>Description – </strong> Provide a detailed overview, including expected outcomes and intended objectives for the activity.</li>
      <li><strong>Data Collected – </strong> List relevant data sources that measure the activity’s effectiveness. Leave blank if none are determined.</li>
  </ol>
</ol>
  </div>`,
    },
    {
      title: "Adding Activities - Step 1",
      content: `<div>
       <h3 class="title-tour">Identifying and Selecting SEL Indicators</h3>
    <span><strong>*SEL Indicators: </strong>For each activity, please select up to 3 SEL indicators that this SEL activity promotes or reinforces:</span>
   <ol>
      <li>From the dropdown, select the SEL indicator that is applicable and click ‘Add Indicator’.</li>
      <li>Then select whether this activity promotes or reinforces this indicator.</li>
      <li>Complete the sentence stem provided to indicate how this activity promotes or reinforces this indicator.</li>
  </ol>
</ol>
  </div>`,
    },
    {
      title: "Adding Activities - Step 1",
      content: `<div>
       <h3 class="title-tour"> Using a System-Populated Activity</h3>
   <ol>
      <li>At the bottom of each domain is a list of possible activities. Select ‘See more’ to see the full list.</li>
      <li>Click the activity to see a description and then select Create this Activity if you wish to add it.</li>
      <li>The activity <strong>name</strong> and <strong>description</strong> fields will automatically populate but you can edit them as needed.</li>
  </ol>
    <p>Then complete any fields that are not already completed.</p>
  </div>`,
    },
    {
      title: "Saving and Editing Activities",
      content: `<div>
      <h3 class="title-tour">Saving and Editing Activities</h3>
         <ol>
           <li class="tour-sub-description">Once all fields are completed, click <strong>“Finish”</strong>.</li>
           <li class="tour-sub-description">Your activity will now appear in the Matrix under the selected domain.</li>
           <li class="tour-sub-description">Click <strong>"Edit"</strong> to modify an existing activity.</li>
           <li class="tour-sub-description">Click <strong>"Delete"</strong> to remove an activity.</li>
           <li class="tour-sub-description">Activities with missing fields, including the <strong>Data Collection</strong> field, will be marked as incomplete on the dashboard.</li>
         </ol>
         <div>
        <span>After adding all activities, you’re ready to view your reports!</span>
        </div>
         <div>
        </div>
       </div>`,
    },
    {
      title: "Matrix Reports",
      content: `<div>
         <h3 class="title-tour">Matrix Reports</h3>
        <ol type="1">
    <li class="tour-sub-description">Click ‘View Report’ on the Matrix dashboard. This will present your activities in several different reports:
        <ol type="a">
            <li class="tour-sub-description"><strong>SEL Scan:</strong> This report presents your Matrix activities by SEL Competency and indicator. You can click each competency to view which activities are promoting or reinforcing each indicator at your school.</li>
            <li class="tour-sub-description"><strong>SEL Quality:</strong></li>
            <li class="tour-sub-description"><strong>Activity Details:</strong></li>
            <li class="tour-sub-description"><strong>DESSA Report:</strong></li>
        </ol>
    </li>
</ol>
       </div>`,
    },
  ],
  fullIndicatorList: [
    "All",
    "Communication",
    "Coordination",
    "Data",
    "Leadership",
    "Physical Visibility",
    "Program Fit/Reinvention",
    "Program Review",
    "Range",
    "School Norms",
  ],
  panelColors: ["#E7A922", "#0CA4A5", "#33485D"],
  implementationDescriptions: [
    {
      title: "Why should I use the Implementation Rubric?",
      content: `<div>
         <h3 class="title-tour">Why should I use the Implementation Rubric?</h3>
         <span>
           <strong>Purpose: </strong>The <strong>Implementation Rubric</strong> helps categorize and assess your school’s current approach to systems related to SEL and their implementation. Understanding your starting point is key to focusing efforts throughout the school year. By rating the 22 indicators of high-quality SEL implementation, you can identify strengths, pinpoint growth areas, and adjust plans based on data.
         </span>
       </div>`,
    },
    {
      title: "Accessing the Implementation Section",
      content: `<div>
         <h3 class="title-tour">Accessing the Implementation Section</h3>
         <p>To access a pre-existing <strong>Implementation Rubric/Report</strong>:</p>
         <ul>
           <li class="tour-sub-description">
           From the main menu, go to Implementation and select your current rubric.
           </li>
            <li class="tour-sub-description">
              Click <strong>"View/Update Rubric"</strong> to edit scores or descriptions or <strong>"Rubrics"</strong> > <strong>"Create New Rubric"</strong> to start a new one.
           </li>
         </ul>
         <div>
         <span>For new users who have not submitted a rubric:</span>
         <p class ="tour-sub-description-implementation">Navigate to <strong>Implementation</strong> from the main menu and select <strong>"Create New Implementation Rubric"</strong>.</p>
          </div>
       </div>`,
    },
    {
      title: "Entering Scores - Step 1",
      content: `<div>
       <h3 class="title-tour">Understanding the Domains</h3>
        <p>Click on each Implementation Rubric Stage (Commitment Stage I & II; Implementation Stage I & II) to expand and review each indicator. Assign a rating based on your community’s plan and execution. Use reflections from the Matrix to inform your responses.</p>
      </div>`,
    },
    {
      title: "Entering Scores - Step 1",
      content: `<div>
        <h3 class="title-tour">Adding Scores and Descriptions</h3>
        <p><strong>Entering Scores</strong></p>
        <ol type="1">
          <li>
            Score Field:
            <ul>
              <li class="tour-sub-description">Enter the score for the selected element based on the predefined criteria.</li>
              <li class="tour-sub-description">The rating scale is 0 ⇒ 4 (Initiating to Integrated). You can hover your mouse over each score to get more information about what this score means (e.g. 0 = No plan in place yet and 4 = plan has been executed).</li>
            </ul>
          </li>
          <li>
            Description Field:
            <ul>
              <li class="tour-sub-description">Once you have scored the indicator, provide a detailed description justifying the score when the box appears. Include specific examples and evidence to support your rating.</li>
              <li class="tour-sub-description">You can utilize the ‘AI Text Enhancement’ tool to improve your description clarity, grammar, and style with real-time suggestions and corrections.</li>
            </ul>
          </li>
           <li>
            Saving Scores and Descriptions:
            <ul>
              <li class="tour-sub-description">Your progress will be saved automatically and your scores and descriptions will be recorded in the Implementation Rubric.</li>
              <li class="tour-sub-description">At any point, you can click ‘Generate Report’ to view your score.</li>
            </ul>
          </li>
        </ol>
      </div>`,
    },
    {
      title: "Saving Scores and Descriptions - Step 2",
      content: `<div>
       <h3 class="title-tour"> Reports (Viewing and Editing)</h3>
       <p><strong>Reviewing and Updating Scores</strong></p>
       <ul>
         <li>
            You can update scores and descriptions at any time.
         </li>
         <li>
            Click <strong>"View/Update"</strong> on the generated report to make changes.
         </li>
         <li>
              Edits are only available for rubrics created during the current school year; older reports cannot be modified.
         </li>
       </ul>
       <p><strong>Viewing the Report</strong></p>
         <ul>
         <li>
          Access your report on the <strong>RSP</strong> site under the <strong>Implementation</strong> tab.
         </li>
         <li> 
            To download, click the <strong>PDF</strong> icon.
         </li>
         <li>
            The report is organized by <strong> Implementation Rubric Stages (Commitment I, Commitment II, Implementation I, Implementation II)</strong>.
         </li>
          <li>
            Each stage highlights <strong>strengths</strong> and <strong>areas for improvement </strong> to guide school planning.
         </li>
       </ul>
     </div>`,
    },
  ],
  sustainabilityDescriptions: [
    {
      title: "Why should I use the Sustainability Rubric?",
      content: `<div>
         <h3 class="title-tour">Why should I use the Sustainability Rubric?</h3>
         <span>
          <strong>Purpose:</strong> A key part of your SEL journey is reviewing program strengths and areas for development while tracking data and artifacts that support these insights. The Sustainability Rubric is essential for assessing the long-term viability of your SEL programming. It helps determine whether initiatives and systems can be maintained over time and what adjustments are needed for ongoing success. This ensures resources are used effectively and that the program’s positive impact is sustained into the future.
         </span>
       </div>`,
    },
    {
      title: "Accessing the Sustainability Section",
      content: `<div>
         <h3 class="title-tour">Accessing the Sustainability Section</h3>    
          <p>Navigate to the <strong>“Sustainability ”</strong>section from the main menu at the top or from your home page, click<strong> ‘View/Add to Sustainability’</strong>.</p>       
       </div>`,
    },
    {
      title: "Entering Scores - Step 1",
      content: `
  <div>
  <h3 class="title-tour">Adding Scores and Descriptions</h3>
  <ul>
    <li>
      Select the sustainability stage - Commitment, Integration, Innovation.
      <ul>
        <li>
          <strong>Score Field:</strong>
          <ul>
            <li class="tour-sub-description">
              Rate your school on each element based on the sustainability criteria - the scale is from Initiating to Well Developed.
            </li>
            <li class="tour-sub-description">
              Each rating will have a description under it, giving an example of what constitutes the rating.
            </li>
          </ul>
        </li>
        <li>
          <strong>Evidence/Description Field:</strong>
          <ul>
            <li class="tour-sub-description">
              Provide a detailed description justifying the score. Highlight the strategies and actions taken to ensure the sustainability of the activity.
            </li>
            <li class="tour-sub-description">
              You can upload files as evidence to support your rating (PDF, JPG, PNG, and GIF). Once uploaded, you need to indicate whether you would like to share your response as an exemplar with the online RSP community.
            </li>
            <li class="tour-sub-description">
              <strong>You need at least one of the above to be able to save your score and generate a report.</strong>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</div>

      `,
    },

    {
      title: "Saving Scores and Descriptions - Step 2",
      content: `<div>
       <h3 class="title-tour">Saving Scores and Descriptions</h3>
       <ol type="1">
         <li>
          Your progress will be saved automatically and your scores and descriptions will be recorded in the Sustainability Rubric.
         </li>
         <li>
          Once all information is entered and scoring is complete, click the “Generate Report” button. 
         </li>
       </ol>
     </div>`,
    },
    {
      title: "Reviewing and Editing - Step 3",
      content: `<div>
        <h3 class="title-tour">Reviewing and Editing</h3>
        <ol type="1">
          <li class="tour-sub-description">You can review the entered scores and descriptions at any time.</li>
          <li class="tour-sub-description">
           To make changes, click the <strong>“Update Rubric”</strong> to put the Sustainability Rubric back into edit more and make the necessary adjustments. </li>
            <li class="tour-sub-description">Updates will be saved automatically, but once all changes are complete, regenerate the report by clicking “Generate Report” with the updated rubric.</li>
            </ol>
          </li>
        </ol>
      </div>`,
    },
  ],
}

export default CommonConstants
